import React, { useState } from "react";
import styled, { css } from "styled-components";
import {mediaMedium} from "./styles/variables";
import Image from "./Image";
import { FadeSlideInRight } from "./styles/animations";
import {useInView} from "react-intersection-observer";

const Wrapper = styled.div`
  width: calc(100% + 40px);
  padding-top: 64px;
  margin: 0 -20px;
  ${(props) => props.theme.responsivePadding && `padding: ${props.theme.responsivePadding}`};


  @media (${mediaMedium}) {
    padding: 0;
    position: ${(props) => !props.theme.staticGrid && 'absolute'};
    max-width: 920px;
    top: ${(props) => props.theme.verticalPosition ? props.theme.verticalPosition : '17%'};
    right: ${(props) => props.theme.horizontalPosition ? props.theme.horizontalPosition : '-9%'};
    z-index: 2;
  };
  
  @media (max-width: 1140px) {
    ${(props) => props.theme.mediaDesktopStyles && props.theme.mediaDesktopStyles};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const Animation = styled(Image)`
  transform: translateX(30%);
  opacity: 0;
  max-width: 100% !important;
  max-height: none !important;
  width: 100%;
  animation: ${(props) =>
    props.active
      ? css`
          ${FadeSlideInRight} 0.8s ease forwards 1s
        `
      : "none"};
  animation-delay: ${(props) => props.delay + 0.1 ?? 0}s;
`;

const Threshold = [0, 0.3, 0.5, 1];

const LandingGridAnimation = ({ Images, animationOnLoad = true, theme }) => {
  const [active, setActive] = useState(false);

  const { ref, inView } = useInView({
    threshold: Threshold,
    delay: 30,
    triggerOnce: true
  });

  const handleLoad = () => {
    animationOnLoad && setActive(true);
  };

  if (inView && !animationOnLoad && !active) {
    setActive(true);
  }

  return (
    <Wrapper ref={ref} theme={theme}>
      <Grid>
        <Animation
          onLoad={handleLoad}
          loading="eager"
          fluid={Images.one.childImageSharp.fluid}
          active={active}
          delay={0.1}
        />

        <Animation
          onLoad={handleLoad}
          loading="eager"
          fluid={Images.three.childImageSharp.fluid}
          active={active}
          delay={0.5}
        />
        <Animation
          onLoad={handleLoad}
          loading="eager"
          fluid={Images.two.childImageSharp.fluid}
          active={active}
          delay={0.3}
        />
        <Animation
          onLoad={handleLoad}
          loading="eager"
          fluid={Images.four.childImageSharp.fluid}
          active={active}
          delay={0.7}
        />
      </Grid>
    </Wrapper>
  );
};

export default LandingGridAnimation;
