import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import HeroSectionExtraHeading from "../HeroSectionExtraHeading";
import HeroSection from "../HeroSection";

// Images
import sponsoredDesktop from "../../images/33-sponsored-content-desktop.png";
import sponsoredMobile from "../../images/33-sponsored-content-mobile.png";
import shopsDesktop from "../../images/stackagency/05-stackmedia-1.png";
import shopsMedia from "../../images/stackagency/05-stackmedia-1.png";
import {black1, brandColor2, white} from "../styles/Colors";

import HorizontalPhonesAnimation from "../HorizontalPhonesAnimation";
import TextHighlight from "../styles/TextHighlight";
import HeroMetricsThree from "../HeroMetricsThree";
import HeroBlueCTA from "../HeroBlueCTA";
import ArrowButton from "../ArrowButton";
import styled from "styled-components";
import AgencyContentAnimation from "../AgencyContentAnimation";
import ContentControlAnimation from "../ContentControlAnimation";
import LandingGridAnimation from "../LandingGridAnimation";
import HeroTwoColumns from "../HeroTwoColumns";
import PagePadding from "../styles/PagePadding";
import HeroContentSnippet from "../HeroContentSnippet";
import AnimatedLineSvg from "../AnimatedLineSvg";
import DecorationRightLarge from "../snippets/DecorationRightLarge";
import {mediaMedium} from "../styles/variables";
import {FadeInBottom} from "../styles/animations";

const shops01Images = {
  desktop: {
    src: shopsDesktop,
    width: 1320,
    height: 734
  },
  mobile: {
    src: shopsMedia,
    width: 175,
    height: 431
  }
};

const sponsoredContentImages = {
  desktop: {
    src: sponsoredDesktop,
    width: 1299,
    height: 1628
  },
  mobile: {
    src: sponsoredMobile,
    width: 375,
    height: 404
  }
};

const Row = styled.span`
  display: block;
  margin-top: 24px;
`

const Line = styled.span`
  @media (${mediaMedium}) {
    position: absolute;
    z-index: 2;
    width: 30%;
    height: 100%;
    top: 26px;
    left: 26.5%;
    bottom: 0;
  }
`;

const PublishersStackAgencyDefer = () => {
  const images = useStaticQuery(graphql`
    query {
      contentOne: file(relativePath: { eq: "58-brands-shop-content-1.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      contentTwo: file(relativePath: { eq: "58-brands-shop-content-2.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      emailOne: file(relativePath: { eq: "59-brands-shop-email-1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      emailTwo: file(relativePath: { eq: "59-brands-shop-email-2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      emailThree: file(relativePath: { eq: "59-brands-shop-email-3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      logoTestimonial: file(
        relativePath: { eq: "28-testimonial-bigthink.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopTestimonial: file(
        relativePath: { eq: "28-testimonial-stackads-desktop.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileTestimonial: file(
        relativePath: { eq: "28-testimonial-stackads-mobile.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopMetrics: file(relativePath: { eq: "53-brands-metric.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileMetrics: file(relativePath: { eq: "53-brands-metric-media.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      fixedFeeOne: file(
        relativePath: { eq: "stackagency/02-phone-fixed-fee-1.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      fixedFeeTwo: file(
        relativePath: { eq: "stackagency/02-phone-fixed-fee-2.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      fixedFeeThree: file(
        relativePath: { eq: "stackagency/02-phone-fixed-fee-3.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      shopsOne: file(
        relativePath: { eq: "stackagency/06-lowshadow-shops-1.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      shopsTwo: file(
        relativePath: { eq: "stackagency/06-lowshadow-shops-2.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      shopsThree: file(
        relativePath: { eq: "stackagency/06-lowshadow-shops-3.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      one: file(relativePath: { eq: "13-publishers-home-1.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      two: file(relativePath: { eq: "13-publishers-home-2.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      three: file(relativePath: { eq: "13-publishers-home-3.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      four: file(relativePath: { eq: "13-publishers-home-4.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <HeroSectionExtraHeading
        heading="WHAT WE OFFER"
        top="01"
        title="COMMERCE & CONTENT EXPERTS"
        theme={{
          paddingDesktop: "144px 0 127px",
          titleMarginDesktop: "138px"
        }}
        images={sponsoredContentImages}
        AnimationComponent={AgencyContentAnimation}
      >
        Our team has extensive expertise in content and performance marketing, and you’ll get monthly planning sessions
        with your StackAgency rep to fine-tune strategy.
      </HeroSectionExtraHeading>

      <HeroSection
        top="02"
        title="FIXED-FEE MEDIA BUYS"
        reverse={true}
        theme={{
          paddingDesktop: "175px 0 202px",
          contentMarginBottom: "54px"
        }}
        displayComponent={
          <HorizontalPhonesAnimation
            theme={{
              width: "450px",
              middleTransform: "calc(57% + 31px)",
              rightTransform: "calc(127% + 32px)",
              positionLarge: "translateX(-75%) translateY(-180%)",
              positionMedium: "translateX(-80%) translateY(-180%)",
              positionSmall: "translateX(-100%) translateY(-210%)",
              rightTop: "6px",
              middleTop: "5px",
              leftMediaWidth: "150%",
              rightMediaWidth: "150%",
              leftMediaTransform: "-17%",
              rightMediaTransform: "-16%",
              rightMediaTop: "-4%",
              rightWidth: "90%",
              cardStyles: "margin-top: -4%;",
              cardDesktopStyles: "margin-top: 0;"
            }}
            images={{
              one: images.fixedFeeOne,
              two: images.fixedFeeTwo,
              three: images.fixedFeeThree
            }}
          />
        }
      >
        Through StackAgency’s relationships with top-tier brands, our business development team secures lucrative
        opportunities via pre-approved performance marketing budgets and channels.
      </HeroSection>

      <HeroSectionExtraHeading
        title="CONTENT CONTROL"
        top="03"
        theme={{
          paddingMedia: "100px 0 80px",
          paddingDesktop: "220px 0 200px",
          titleMarginMedia: "0",
          titleMarginDesktop: "0",
          backgroundColor: black1
        }}
        AnimationComponent={ContentControlAnimation}
      >
        We work directly with your editors, supplying them with all the needed assets for content production, allowing
        you more control over articles.
      </HeroSectionExtraHeading>

      <HeroSection
        noPadding={true}
        theme={{
          paddingMedia: '0 0 0 0',
          paddingDesktop: '0 0 0 0'
        }}
        noTextContent
        displayComponent={
          <Line>
            <AnimatedLineSvg
              duration={1000}
              triggerOffset={50}
              theme={{displayProps: "left : 110%; transform: rotateY(180deg);"}}
            >
              <DecorationRightLarge/>
            </AnimatedLineSvg>
          </Line>
        }
      >
        <HeroContentSnippet
          title="STACK IT UP"
          padding={'0 0 200px'}
          noBreak
        >
          Want more ways to earn? Add on other StackCommerce solutions and
          watch your revenue stack up.
        </HeroContentSnippet>
      </HeroSection>

      <HeroSection
        top="01"
        title="STACKMEDIA "
        images={shops01Images}
        animation={FadeInBottom}
        theme={{
          marginTop: "-180px !important",
          paddingMedia: '180px 0 0px',
          paddingDesktop: '180px 0 93px',
          overflowWidth: "1000",
          imageLeft: "44%",
          imageTop: "-15%",
          imgTabletWidth: "1000px",
          imgWidth: "100%",
          imgTransform: "0",
          backgroundColor: "transparent",
          contentMarginBottom: 0
        }}
      >
        Flat-rate and CPC-based solutions via an extension of your ad sales team. Earn with every click or repeat buys
        from top brands to deliver consistent, predictable revenue without compromise or added editorial resources.
        <Row>
          <ArrowButton
            attrs={{as: Link, to: '/publishers/stack-media/'}}
          >
            Find Out More
          </ArrowButton>
        </Row>
      </HeroSection>

      <HeroSection
        top="02"
        title="SHOPS"
        backgroundColor={brandColor2}
        alt={true}
        reverse={true}
        theme={{
          paddingDesktop: "188px 0 167px",
          contentMarginBottom: "54px"
        }}
        displayComponent={
          <HorizontalPhonesAnimation
            theme={{
              width: "450px",
              middleTransform: "calc(30%)",
              rightTransform: "calc(60%)",
              positionLarge: "translateX(-75%) translateY(-210%)",
              positionMedium: "translateX(-80%) translateY(-100%)",
              positionSmall: "translateX(-80%) translateY(-100%)",
              rightTop: "11px",
              middleTop: "5px",
              leftMediaWidth: "180%",
              middleMediaWidth: "180%",
              rightMediaWidth: "180%",
              leftMediaTransform: "-19%",
              rightMediaTransform: "-26%",
              leftWidth: "150%",
              middleWidth: "150%",
              rightWidth: "150%",
              rightMediaTop: "-4%",
            }}
            images={{
              one: images.shopsOne,
              two: images.shopsTwo,
              three: images.shopsThree
            }}
          />
        }
      >
        Have StackCommerce power your custom, branded Shop with zero out-of-pocket costs and get access to a suite of
        commerce and content tools, including visitor data and AI-driven curation.

        <Row>
          <ArrowButton
            attrs={{as: Link, to: '/publishers/shops/'}}
            variant="white"
          >
            Find Out More
          </ArrowButton>
        </Row>
      </HeroSection>

      <HeroTwoColumns align="center">
        <PagePadding>
          <HeroSection
            top="03"
            title="AFFILIATE MANAGEMENT"
            theme={{
              contentMarginBottom: "54px",
              paddingMedia: "60px 0 0",
              paddingDesktop: "200px 0 150px",
              overflow: "visible",
              backgroundColor: "transparent",
              wrapperStyles: "z-index: 3;"
            }}
            noGrid

          >
            Leverage affiliate opportunities with viral potential,
            {" "}
            <TextHighlight theme={{background: white}}>
              exclusive inventory
            </TextHighlight>
            {" "}
            , and industry-leading CPA payouts via
            content produced by StackCommerce or your own team.

            <Row>
              <ArrowButton
                attrs={{as: Link, to: '/publishers/affiliate/'}}
              >
                Find Out More
              </ArrowButton>
            </Row>
          </HeroSection>
        </PagePadding>
        <LandingGridAnimation
          Images={images}
          animationOnLoad={false}
          theme={{
            horizontalPosition: '-300px',
            verticalPosition: '6%',
            responsivePadding: '0 0 80px',
            mediaDesktopStyles: 'position: initial;'
          }}
        />
      </HeroTwoColumns>

      <HeroMetricsThree
        backgroundMedia={images.mobileMetrics}
        backgroundDesktop={images.desktopMetrics}
        content={[
          {
            number: "180",
            suffix: "M+",
            description: "Total Reach"
          },
          {
            number: "26",
            suffix: "%",
            description: "Avg CTR from Content </br> to Product"
          },
          {
            number: "700",
            suffix: "+",
            description: "Publishers in Our Network"
          }
        ]}
      />

      <HeroBlueCTA
        title="GET REAL RESULTS"
        outline="WITH STACK"
        description="See how StackAgency can help you reach your target audiences on the sites they visit daily."
        linkLabel="Get Started"
        link={{
          href: "mailto:agency@stackcommerce.com?subject=STACKAGENCY%20For%20Publishers",
          as: 'a',
          label: 'Get Started'
        }}
      />
    </>
  );
};

export default PublishersStackAgencyDefer;
